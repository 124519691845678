// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCLvQdlhnCZiNoUpzXrVze5g6FWBIp4doQ',
    authDomain: 'osha-dev-e024d.firebaseapp.com',
    databaseURL: 'https://osha-dev-e024d.firebaseio.com',
    projectId: 'osha-dev-e024d',
    storageBucket: 'osha-dev-e024d.appspot.com',
    messagingSenderId: '341178485434',
    appId: '1:341178485434:web:523568d89dec2b99af5083',
    measurementId: 'G-EBGWG2VWR5'
  },
  google_drive: {
    driveConfig: {
      clientId: '341178485434-8png9g5tid23jrtoedoh9dh812ov61hf.apps.googleusercontent.com',
      discoveryDocs: [
        'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
      ],
      scope: 'https://www.googleapis.com/auth/drive.readonly'
    },
    APP_ID: '341178485434'
  },
  version: '0.0.6'
  // firebaseConfig: {
  //   apiKey: 'AIzaSyA1Xia725799awT7BTMZBVDQN0EW09VDRA',
  //   authDomain: 'osha-b65ae.firebaseapp.com',
  //   databaseURL: 'https://osha-b65ae.firebaseio.com',
  //   projectId: 'osha-b65ae',
  //   storageBucket: 'osha-b65ae.appspot.com',
  //   messagingSenderId: '536860153158'
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
